<template>
  <div>
    <Menu />
    <div id="searchPage">
      <!-- crumbs -->
      <div class="crumbs">
        <div class="content d-flex d-flex-middle">
          <p class="color9 fontSize12">当前所在位置：</p>
          <a href="index.html" class="color9 fontSize12">首页</a>
          <p class="color9 fontSize12 and">></p>
          <p class="color fontSize12">搜索结果</p>
        </div>
      </div>
      <!--  -->
      <div class="results content d-flex d-flex-middle">
        <p class="color9 fontSize12">共有</p>
        <p class="color fontSize12">{{total}}</p>
        <p class="color9 fontSize12">个包含“</p>
        <p class="txt fontSize12">{{searchVal}}</p>
        <p class="color9 fontSize12">”的相关内容</p>
      </div>
      <!-- 课程list -->
      <div class="list" v-if="searchType==1">
        <div class="content d-flex d-flex-middle d-flex-wrap">
          <div class="ecLi" v-for="(item,index) in courseList" :key="index">
            <div class="ec_content">
              <div class="ec_img" :style="{'background-image': 'url('+$store.state.httpUrl + item.course_ImaPath+')'}"></div>
              <div class="ec_info">
                <p class="ec_title">{{item.course_Title}}</p>
                <div class="ec_bottom d-flex d-flex-middle d-flex-between">
                  <div class="ec_price">
                    <p>￥{{item.course_Price}}</p>
                    <p>特惠价</p>
                  </div>
                  <div class="ec_icon">
                    <img src="../assets/images/audition.png" />
                    <p>免费试听</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- 真题list -->
      <div class="list list_b content bg_fff" v-if="searchType==2">
        <div class="li_w d-flex d-flex-middle d-flex-between" v-for="(item,index) in exercisesList" :key="index">
          <div class="d-flex d-flex-middle">
            <img src="../assets/images/file.png" class="li_img" />
            <div class="liInfo">
              <p class="li_title">{{item.exercisesPaper_Title}}</p>
              <div class="d-flex d-flex-middle">
                <div class="d-flex d-flex-middle">
                  <p class="color9 fontSize12">大小：</p>
                  <p class="color3 fontSize12">{{item.exercisesPaper_Title}}</p>
                </div>
                <div class="d-flex d-flex-middle">
                  <p class="color9 fontSize12">时间：</p>
                  <p class="color3 fontSize12">{{item.exercisesPaper_ReleaseDateTime}}</p>
                </div>
                <div class="d-flex d-flex-middle">
                  <p class="color9 fontSize12">类型：</p>
                  <p class="color3 fontSize12">{{item.exercisesPaper_Ext}}</p>
                </div>
              </div>
            </div>
          </div>
          <a @click="handDown(item)" class="d-flex d-flex-middle d-flex-center download">
            <span>免费下载</span>
            <img src="../assets/images/download.png" />
          </a>
        </div>
      </div>

      <div style="text-align: center; margin-top: 60px;margin-bottom: 60px;" v-if="courseList.length>0 ||exercisesList.length>0 ">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total" :page-size="8">
        </el-pagination>
      </div>
    </div>
    <Foot />
    <RightGuide />
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { splitListCourse, splitListPaper } from "@/api/app"
import { downloadByBlob } from "@/utils/index.js"
export default {
  components: {
    Menu, Foot, RightGuide
  },
  data() {
    return {
      searchVal: '',
      searchType: '',
      courseList: [],
      exercisesList: [],
      page: 1,
      total: 0,
    }
  },
  mounted() {
    console.log(this.$route.query)
    if (this.$route.query.searchValue) {
      this.searchVal = this.$route.query.searchValue
      this.searchType = this.$route.query.searchType
    }

    if (this.searchType == 1) {
      console.log('aa')
      this.getCourse()
    } else {
      console.log('aa')
      this.getExercises()
    }
  },
  watch: {
    $route(to, from) {
      // 判断跳转的路径是否你要监听的路径，
      if (to.path == "/Search") {
        // 通过路径获取新的 keywords 值
        let param = to.query.searchValue;

        // 通过新的参数值获取新的数据
        this.page = 1
        this.searchVal = this.$route.query.searchValue
        this.searchType = this.$route.query.searchType

        if (this.searchType == 1) {
          this.getCourse()
        } else {
          this.getExercises()
        }
      }
    }
  },
  methods: {

    handDown(item) {
      //截取文件格式
      let itemUrl = item.exercisesPaper_Path
      let index = itemUrl.lastIndexOf('.')
      let str = itemUrl.substring(index + 1, itemUrl.length)

      const type = 'image/jpeg/png/gif'

      if (type.includes(str)) {
        console.log(str)
        downloadByBlob((this.$store.state.httpUrl + item.exercisesPaper_Path), item.exercisesPaper_Title)
      }
      else {
        window.location = this.$store.state.httpUrl + item.exercisesPaper_Path
      }
    },

    //课程列表
    getCourse(page) {
      const params = {
        industry_Id: '',
        course_Type: '',
        val: this.searchVal,
        page: page || this.page,
      }
      splitListCourse(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.courseList = data.listCourse
          this.total = data.totalCount
        }
      })

    },

    //真题列表
    getExercises(page) {
      const params = {
        industry_Id: '',
        course_Type: '',
        val: this.searchVal,
        page: page || this.page,
      }

      splitListPaper(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.exercisesList = data.listCourse
          this.total = data.totalCount
        }
      })
    },


    //分页
    handleCurrentChange(page) {
      if (this.searchType == 1) {
        this.getCourse(page)
      } else {
        this.getExercises(page)
      }
    },
  }
}
</script>

<style lang="scss" >
#searchPage {
  .results {
    height: 60px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 40px;
  }
  .results .txt {
    color: red;
  }
  /*  */
  .list {
    box-sizing: border-box;
    padding: 30px;
    margin-bottom: 100px;
    // display: none;
  }
  .list_b {
    margin-top: 30px;
  }
  /*  */
  .ecLi {
    width: 25%;
    margin-bottom: 20px;
  }
  .ec_content {
    width: 294px;
    margin: 0 auto;
    height: 300px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .ec_img {
    height: 164px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .ec_img img {
    position: absolute;
    right: 0;
    top: 0;
    width: 86px;
    height: 86px;
  }
  .ec_info {
    box-sizing: border-box;
    padding: 10px;
  }
  .ec_title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
  .ec_bottom {
    margin-top: 20px;
  }
  .ec_price p {
    color: #e02727;
    font-size: 14px;
  }
  .ec_price p:nth-of-type(1) {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    text-indent: -5px;
  }
  .ec_icon {
    text-align: center;
    color: #0091ff;
    font-size: 14px;
  }
  .ec_icon img {
    width: 30px;
    height: 30px;
  }
  /*  */
  .li_w {
    box-sizing: border-box;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
  }
  .li_img {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }
  .liInfo .li_title {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    max-width: 1000px;
    margin-bottom: 10px;
  }
  .liInfo div {
    margin-right: 30px;
  }
  .download {
    width: 130px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #0087ed;
    border-radius: 18px;
    color: #0087ed;
  }
  .download img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
}
</style>