export const inArray = (search, array) => {
    for (var i in array) {
        if (array[i] == search) return true
    }
    return false
}

export const downloadByBlob = (url, name) => {
    let image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
    image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
            let url = URL.createObjectURL(blob)
            download(url, name)
            // 用完释放URL对象
            URL.revokeObjectURL(url)
        })
    }
}

export const download = (href, name) => {
    let eleLink = document.createElement('a')
    eleLink.download = name
    eleLink.href = href
    eleLink.click()
    eleLink.remove()
}